<template>
	<div
		class="mb-50 mt-30"
	>
		<div class="flex-row">
			<div
				class="pa-24 bg-white radius-20 box-contents mr-30"
			>
				<div
				>
					<h3 class="text-left color-primary size-px-20">글쓰기</h3>
					<hr class="under-line-bbb mt-16">

					<div class="mt-20 bg-white flex-1">
						<div
							v-if="!is_notice"
							@click="onBoard"
							class="text-left box radius-20 justify-space-between align-center cursor-pointer size-px-14"
							style="border: 1px solid #dadada; padding:7px 20px;"
						>
							<div>{{ text_board_name }}</div>
							<button
							><v-icon>mdi-chevron-down</v-icon></button>
						</div>
						<div
							v-else
							class="text-left box radius-20 pa-10-20"
						>공지사항</div>

						<div
							v-if="is_select_board_type"
							class=" mt-10 radius-10 pa-10 bg-gray01"
						>
							<ul
								@cancel="is_select_board_type = false"
								class="scroll-black select-board-type"
								style="max-height: 150px; overflow: auto"
							>
								<template
									v-for="(item, index) in items_board_config"
								>
									<li
										v-if="item.board_authority.reg_fg == 'Y'"
										:key="'item_' + index"
										class=" pb-10-not-last color-gray cursor-pointer pl-10"
										:class="{on: item.board_number == item_board_config.board_number }"
										@click="setBoard(item)"
									>{{ item.board_name }}</li>
								</template>
							</ul>
						</div>

						<div
							v-if="is_notice"
							class="mt-20 text-left"
						>
							<div class="">
								<input
									v-model="item_bbs.title"
									placeholder="제목을 입력하세요."
									class="width-100 box radius-20 pa-10-20 color-333"
									style="border: 1px solid #dadada"
									maxlength="50"
								/>
							</div>
						</div>

						<div class="mt-20 pa-20 scroll-black box radius-10  "
							style="border: 1px solid #dadada">
							<!-- TODO : 내용입력시 placeholde 및 .ct_empty 클래스 제거 -->
							<textarea
								v-model="item_bbs.content"
								placeholder="내용을 입력하세요."
								ref="content"
								rows="20"
								class="size-px-14 color-333"
								style="width: 100%;"

								@input="reSize"

							></textarea>
						</div>

						<div class="text-left">
							<div class="ct_text_editor">

								<div class="ct_container">
								</div>

								<!-- //글쓰기영역 -->

							</div>
						</div>

						<div
							v-if="is_notice"
							class="mt-20 flex-row justify-space-between align-center"
						>
							<div class=""><em class="size-px-16 font-weight-500">댓글 작성</em> <span class="color-gray">댓글 작성 여부를 선택합니다.</span></div>
							<div class="option_check-toggle">
								<p class="toggle-group">
									<!-- TODO : 수신 허용시 .on 클래스 추가 -->
									<label><span class="btn-toggle" :class="{ on: item_bbs.is_comment}"><input v-model="item_bbs.is_comment" type="checkbox" value="true" style="visibility: hidden"/><em class="hide">공지사항 알림</em></span></label>
								</p>
							</div>
						</div>
					</div>

					<mafia_vote
						v-if="is_vote"
						:vote="item_bbs.vote"
						:cartel="item_cartel"
						:bbs="item_bbs"
						:user="user"

						@cancel="removeVote"
						@change="setVote"

						class="mt-20"
					></mafia_vote>

					<template
						v-if="files.length > 0"
					>
						<hr class="under-line mt-20"/>
						<!-- 미디어 영역 -->
						<div class="mt-20 text-left size-px-16 font-weight-500"> 첨부파일 </div>

						<div
							class="mt-20"
						>
							<ul class="media_wrap">
								<draggable
									v-model="files"
									handle=".handle"
								>
									<li
										v-for="(file, index) in files"
										:key="'files_' + index"
										class="flex-row mb-10-not-last items-center"
									>
										<div
											class="photo_upload_item position-relative bg-gray"
										>
											<img
												v-if="file.type == 'CA01000001'"
												:src="file.src"
												class="object-cover"
											/>

											<video
												v-else-if="file.type == 'CA01000002'"
												width="100px"
												height="100px"

												:ref="'video_' + index"
											>
												<source
													:src="file.src"
													type="video/webm"
												>
											</video>

											<video
												v-else-if="file.type == 'CA01000003'"
												width="100px"
												height="100px"
												:src="file.src"
												:poster="require('@/assets/image/web/notify_cartel/ico_noti_sound.svg')"
												style="object-fit: cover"
											></video>

											<button
												class="position-absolute bg-white radius-100"
												style="top: 5px; right: 5px;"
												@click="removeFile(index)"
											>
												<v-icon
												>mdi-close-circle-outline</v-icon>
											</button>
										</div>

										<div class="flex-1 pl-10">
											<p>{{  file.name }}</p>
										</div>

										<div class="cursor-pointer handle">
											<div class="drag_bar">
												<img :src="require('@/assets/image/btn_order.png')" alt="">
											</div>
										</div>
									</li>
								</draggable>
							</ul>
						</div>
						<!-- //미디어 영역 -->
					</template>

					<div
						v-if="is_subscribe"
						class="size-px-16  pt-10 pb-50"
					>
						<div
							v-if="items_payment.length > 0"
							class="pa-20 bg-white text-left"
						>
							<div>적용 플랜 선택</div>

							<div class="mt-10 size-px-13 color-gray">선택된 플랜 등급이상 구독 멤버에 한해 이글을 볼 수 있습니다.</div>
							<div class="mt-10">
								<ul>
									<li
										v-for="(payment, p_index) in items_payment"
										:key="'payment_' + p_index"

										class="mt-10"
									>
										<button
											@click="setPlan(payment)"
										>
											<v-icon
												v-if="payment.is_check"
												class="color-blue"
											>mdi-checkbox-marked-circle</v-icon>
											<v-icon
												v-else
											>mdi-checkbox-blank-circle-outline</v-icon>
											<span class="size-px-14 ml-10">{{ payment.subscrp_plan_name}}</span>
										</button>
									</li>
								</ul>
							</div>
						</div>
						<div
							v-else
							class="pa-20 bg-white text-left justify-space-between items-center"
						>
							등록된 플랜이 없습니다.
							<button
								class="btn-inline btn-primary"
								@click="toPlan"
							>플랜 등록</button>
						</div>

						<hr class="top-line">

						<div
							class="pa-20 bg-white"
						>
							<div class="justify-space-between items-center" @click="onSpecial">
								고급설정
								<v-icon>mdi-chevron-right</v-icon>
							</div>
							<div class="mt-20 justify-space-between" @click="onList">
								글 불러오기
								<v-icon>mdi-chevron-right</v-icon>
							</div>
						</div>
					</div>

					<div class="flex-row justify-start ptb-20 toolbar under-line"
						>
							<template
								v-if="!is_cheer"
							>
								<button @click="toFile"><label><span class="toolbar_icon tb_photo mr-20 color-888 size-px-14">사진</span><input_file accept="image/*" multiple @change="setFile('image', $event)"
								/></label></button>

								<button @click="toFile"><label><span class="toolbar_icon tb_viedo mr-20 color-888 size-px-14" >비디오</span><input_file accept="video/*" multiple @change="setFile('video', $event)" ref="file_video"/></label></button>

								<button @click="toFile"><label><span class="toolbar_icon tb_sound mr-20 color-888 size-px-14" >사운드</span><input_file accept="audio/*" multiple @change="setFile('audio', $event)" ref="file_sound"/></label></button>

								<button
									@click="resetVote"
								><span class="toolbar_icon tb_vote color-888 size-px-14">투표</span></button>
							</template>
						</div>

					<div
						class="bg-white pt-20 flex-row justify-end items-center"
					>

						<div class="text-right">
							<button
								class="btn-inline btn-dark-gray radius-20 mr-10 pa-10-30"
								@click="isCancel"
							>취소</button>
							<button
								class="btn-inline btn-primary radius-20 pa-10-30"
								@click="doPost"
							>완료</button>
						</div>
					</div>
				</div>
			</div>

			<CartelEtc
				v-if="item_cartel.cartl_member_grade_chg_info"
				:user="user"
				:cartel_info="item_cartel"
				:bbs_info="item_bbs"
			></CartelEtc>
		</div>


		<PopupSelect
			v-if="is_on_select"
			@cancel="is_on_select = false"
		>
			<template
				v-slot:title
			>게시판 선택</template>
			<template
				v-slot:list
			>
				<template
					v-for="(item, index) in items_board_config"
				>
					<li
						v-if="item.board_type_code != 'CA00700001'"
						:key="'item_' + index"
						style="padding: 10px 0"
						@click="setBoard(item)"
						class="cursor-pointer"
					>{{ item.board_name }}</li>
				</template>
			</template>
		</PopupSelect>


		<PopupConfirm
			v-if="is_popup"
			@cancel="is_popup = false"
			@click="setVideo"
		>
			<template
				v-slot:title
			>비디오</template>
			<template
				v-slot:main-txt
			>
				유튜브 링크를 입력하세요
			</template>
			<template
				v-slot:sub-txt
			>
				<div
					v-for="i in video_size"
					:key="'i_' + i"
				>
					<input
						v-model="video[video_size - i]"
						type="text"
						style="border: 1px solid #ddd; padding: 10px"
						class="mt-10"
						placeholder="https://"
					/>
					<v-icon
						v-if="i == video_size"
						@click="setVideoSize(true, i)"
						class="ml-10 mt-5"
					>mdi-plus-circle-outline</v-icon>
					<v-icon
						v-else
						@click="setVideoSize(false, i)"
						class="ml-10 mt-5"
					>mdi-minus-circle-outline</v-icon>
				</div>
			</template>
		</PopupConfirm>

		<PopupLayer
			v-if="is_on_special"
		>
			<template
				v-slot:body
			>
				<div class="width-320  ma-auto bg-white  radius-20 overflow-hidden">
					<div class="pa-20">
						<h3 class="pop_tit pop_tit_logo text-center under-line mt-30 pb-30">고급설정</h3>
						<div
							class=" mt-10 bg-white"
						>
							<div>
								<div
									class="justify-space-between items-center"
									@click="$set(item_bbs, 'bdy_release_fg', item_bbs.bdy_release_fg == 'Y' ? 'N' : 'Y')"
								>
									<h4 class="size-px-16">본문 공개</h4>
									<p class="toggle-group">
										<!-- TODO : 수신 허용시 .on 클래스 추가 -->
										<span
											class="btn-toggle"
											:class="{ on: item_bbs.bdy_release_fg == 'Y' }"
										><em class="hide">{{  $language.common.dark_mode }}</em></span>
									</p>
								</div>
								<div class="mt-10 size-px-13 color-gray text-left">비구독 멤버에게 본문을 공개합니다</div>
							</div>
							<div class="mt-20">
								<div
									class="justify-space-between items-center"
									@click="$set(item_bbs, 'comment_release_fg', item_bbs.comment_release_fg == 'Y' ? 'N' : 'Y')"
								>
									<h4 class="size-px-16">댓글 공개</h4>
									<p class="toggle-group">
										<!-- TODO : 수신 허용시 .on 클래스 추가 -->
										<span
											class="btn-toggle"
											:class="{ on: item_bbs.comment_release_fg == 'Y' }"
										><em class="hide">{{  $language.common.dark_mode }}</em></span>
									</p>
								</div>
								<div class="mt-10 size-px-13 color-gray  text-left">비구독 멤버에게 댓글을 공개합니다</div>
							</div>
						</div>
					</div>
					<div
						class=" justify-space-between "
					>
						<button
							class="flex-1 btn_fill_gray pa-10"
							@click="offSpecial"
						>닫기</button>
						<button
							class="flex-1 btn_fill_blue pa-10"
							@click="postSubscribe"
						>확인</button>
					</div>
				</div>
			</template>
		</PopupLayer>
	</div>
</template>

<style>
	.m_video {	display: flex; flex-direction: column; justify-content: center; border: 1px solid #ddd; background-color: var(--bg-Gray02); border-radius: 10px; overflow: hidden}

	.article_write_form_subject { font-size: 16px; display: flex; justify-content: space-between; padding: 10px}

	.ct_textarea textarea { height: none; min-height: none;}

</style>

<script>

import PopupConfirm from "@/view/Layout/PopupConfirm";
import draggable from 'vuedraggable'
import mafia_vote from '@/view/Cartel/mafiaVote'
import input_file from '@/components/InputFile'
import PopupSelect from "@/view/Layout/PopupSelect";
import CartelEtc from "@/view/Cartel/CartelEtc";
import PopupLayer from "@/view/Layout/PopupLayer";

export default {
	name: 'mafia1271'
	, components: {PopupLayer, CartelEtc, PopupSelect, PopupConfirm, draggable, mafia_vote, input_file}
	, props:['user']
	, data: function(){
		return {
			program: {
				name: ''
				, title: ''
				, type: 'cartel_sub'
				, not_footer: true
				, not_header: true
				, bg_contents: 'bg-gray01'
				, cartel: {}
			}
			, item_cartel: {}
			, item_board_config: {
				id: ''
			}
			, item_bbs: {
				code: this.$route.params.code
				, user_id: this.user.id
				, nick: this.user.nick
				, video:[]
				, vote: {
					title: ''
					, items: [
						{ img_src: '', title: ''}
						, { img_src: '', title: ''}
					]
					, date: this.$date.getToday('-')
					, time: ['pm', '12', '00']
					, is_multiple: true
				}
				, title: ''
				, content: ''
				, is_comment: true
				, date: ''
				, bdy_release_fg: 'Y'
				, comment_release_fg: 'Y'
			}
			, files: []
			, video: []
			, video_size: 1
			, items_board_config: []
			, is_select_board_type: false
			, bbs_id: this.$route.params.bbs_id
			, is_popup: false
			, file_max: 8
			, is_vote: false
			, vote_fg: false
			, post_file_list: []
			, upload_files: []
			, item_select_board: {

			}
			, is_on_select: false
			, items_payment: []
			, is_on_special: false
			, is_on_list: false
			, items_list: []
			, item_copy: {}
		}
	}
	, computed: {
		is_disabled: function(){
			let t = false
			if(!this.item_bbs.content || this.item_bbs.content.trim() == ''){
				t = true
			}
			if(this.item_board_config.code == 'CA00700001' && (!this.item_bbs.title || this.item_bbs.title == '')){
				t = true
			}
			return t
		}
		, is_cheer: function(){
			let t = false
			if(this.item_board_config.board_type_code == 'CA00700002'){
				t = true
			}
			return t
		}
		, text_title: function(){
			let t = '글쓰기'

			if(this.$route.params.bbs_id){
				t = '글수정'
			}

			return t
		}
		, text_board_name: function(){
			let t = ''

			t =  this.item_board_config.board_name

			return t
		}
		, is_notice: function(){
			let t = false

			if(this.item_board_config.board_type_code == 'CA00700001'){
				t = true
			}

			return t
		}
		, vote_info: function(){
			if(this.vote_fg){
				return {
					vote_info: ''
					, vote_title: this.item_bbs.vote.title
					// , vote_startdt: this.item_bbs.vote.date + ' ' + this.item_bbs.vote.time.join(':')
					, vote_enddate: this.makeEnddate()
					, vote_type_code: this.item_bbs.vote.is_multiple ? 'CA01400002' : 'CA01400001'
				}
			}else{
				return {

				}
			}
		}
		, is_subscribe: function(){
			let t = false
			if(this.item_board_config.board_type_code == 'CA00700005'){
				t = true
			}
			return t
		}
		, post_subscrp_plan_list: function(){
			return this.items_payment.filter( (item) => {
				if(item.is_check){
					return {
						subscrp_plan_number: item.subscrp_plan_number
					}
				}
			})
		}
	}
	,methods: {
		getBoardConfig: async function(){

			try {

				if(!this.$route.params.b_id){
					if(this.items_board_config[0].board_type_code == 'CA00700001'){
						this.items_board_config.splice(0, 1)
						this.$log.console(this.items_board_config)
					}
					this.$set(this, 'item_board_config', this.items_board_config[0])
				}else{

					for(let b of this.items_board_config){
						if(b.board_number == this.$route.params.b_id){
							this.item_board_config = b
							break
						}
					}
				}
			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, postFiles: async function (){
			return true
		}
		, postFile: async function(appendix_file_div_code, file){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_file
					, data: {
						member_number: this.user.member_number
						, file_upload_code: 'CM00700003'
						, appendix_file_div_code: appendix_file_div_code
						, appendix_file_name: file
					}
					, multipart: true
					, type: true
				})

				if(result.success){
					return result.data.file_url
				}else{
					throw result.message
				}

			}catch (e) {
				this.$log.console('postFile error', e)
				this.$bus.$emit('notify', { type: 'error', message: e})
				return false
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, postNotice: async function(){

			try {
				this.$bus.$emit('on', true)

				if(await this.postFiles()){
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_notice
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, board_number: this.$route.params.b_id
							, post_title: this.item_bbs.title
							, post_contents: this.item_bbs.content
							, file_appendix_fg: this.post_file_list.length > 0 ? 'Y' : 'N'
							, post_file_list: this.post_file_list
							, vote_fg: this.vote_fg ? 'Y' : 'N'
							, vote_info: this.vote_info
							, vote_list: this.item_bbs.vote.items
							, comment_writing_fg: this.item_bbs.is_comment ? 'Y' : 'N'
						}
						, type: true
					})

					if(result.success){
						this.$emit('to', { name: 'mafia053', params: { idx: this.$route.params.idx, code: this.$route.params.code, b_id: this.$route.params.b_id }})
					}else{
						throw result.message
					}
				}

			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, postCheer: async function(){
			try {
				this.$bus.$emit('on', true)

				if(await this.postFiles()){
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_cheer
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, board_number: this.item_board_config.board_number
							, post_contents: this.item_bbs.content
						}
						, type: true
					})

					if(result.success){
						this.$emit('to', { name: 'mafia053', params: { idx: this.$route.params.idx, code: this.item_board_config.board_type_code, b_id: this.item_board_config.board_number }})
					}else{
						throw result.message
					}
				}

			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, postSubscribe: async function(){
			try {
				this.$bus.$emit('on', true)

				if (await this.postFiles()) {
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_subscribe_board
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, board_number: this.item_board_config.board_number
							, post_contents: this.item_bbs.content
							, file_appendix_fg: this.post_file_list.length > 0 ? 'Y' : 'N'
							, post_file_list: this.post_file_list
							, vote_fg: this.vote_fg ? 'Y' : 'N'
							, vote_info: this.vote_info
							, vote_list: this.item_bbs.vote.items
							, bdy_release_fg: this.item_bbs.bdy_release_fg
							, comment_release_fg: this.item_bbs.comment_release_fg
							, post_subscrp_plan_list: this.post_subscrp_plan_list
						}
						, type: true
					})

					if (result.success) {
						this.$emit('to', {name: 'mafia053',
							params: {
								idx: this.$route.params.idx,
								code: this.item_board_config.board_type_code,
								b_id: this.item_board_config.board_number
							}
						})

					} else {
						throw result.message
					}
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('on', false)
				this.$bus.$emit('notify', {type: 'error', message: e})
			}
		}
		, postBbs: async function(){
			try {
				this.$bus.$emit('on', true)

				if(await this.postFiles()){
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_bbs
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, board_number: this.item_board_config.board_number
							, post_contents: this.item_bbs.content
							, file_appendix_fg: this.post_file_list.length > 0 ? 'Y' : 'N'
							, post_file_list: this.post_file_list
							, vote_fg: this.vote_fg ? 'Y' : 'N'
							, vote_info: this.vote_info
							, vote_list: this.item_bbs.vote.items
							, comment_writing_fg: this.item_bbs.is_comment ? 'Y' : 'N'
						}
						, type: true
					})

					if(result.success){
						this.$emit('to', { name: 'mafia053', params: { idx: this.$route.params.idx, code: this.item_board_config.board_type_code, b_id: this.item_board_config.board_number }})

					}else{
						throw result.message
					}
				}

			}catch (e) {
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, getBoardList: async function() {
			try {
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_menu
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
					}
					, type: true
				})

				if (result.success) {
					this.items_board_config = result.data.board_list
					this.$bus.$emit('setBoardConfigSort', this.items_board_config)
				} else {
					throw result.message
				}
			} catch (e) {
				this.$log.console(e)
				this.$bus.$emit('notify', {type: 'error', message: e})
			} finally {
				this.$bus.$emit('on', false)
			}
		}
		, getCartel: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_info
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
					}
					, type: true
				})

				if(result.success){
					this.item_cartel = result.data
					this.program.cartel = result.data
				}else{
					throw result.message
				}
			}catch (e) {
				this.$log.console(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, onBoard: function(){
			this.is_on_select = true
			// this.is_select_board_type = !this.is_select_board_type
		}
		, setBoard: function(item){
			if(item.board_authority.reg_fg != 'Y'){
				this.$bus.$emit('notify', { type: 'error', message: '게시물 작성 권한이 없는 게시판입니다.'})
			} else{

				this.is_select_board_type = false
				this.item_board_config = item
				this.item_bbs.code = item.code
				this.$log.console('this.item_board_config', this.item_board_config)
				this.is_on_select = false
			}
		}
		, removeFile: function(index){
			this.files.splice(index, 1)
			this.upload_files.splice(index, 1)
			this.post_file_list.splice(index, 1)
		}
		, removeVideo: function(index){
			this.video.splice(index, 1)
		}
		, getBbs: async function(){
			try{
				if(!this.bbs_id){
					return
				}
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_bbs
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, board_number: this.$route.params.b_id
						, bulletin_number: this.$route.params.bbs_id
					}
					, type: true
				})

				if(result.success){

					this.item_bbs = result.data
					if(this.item_bbs.files){
						this.files = this.item_bbs.files
					}
					if(this.item_bbs.video){
						this.video = this.item_bbs.video
						this.video_size = this.video.length
					}
					if(this.item_bbs.vote){
						this.is_vote = true
					}

					this.item_bbs.content = this.item_bbs.post_contents

					this.$refs.content.style.height = 'auto'
					this.$refs.content.style.height = this.$refs.content.offsetHeight + 'px'
				}else{
					throw result.message
				}
			}catch (e) {
				this.$log.console(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, onPopup: function(){
			this.is_popup = true
		}
		, setFile: function(type, e){
			if(e.length > 0) {

				let file_count = this.files.length + e.length
				if (file_count > this.file_max) {
					this.$bus.$emit('notify', {type: 'error', message: this.$language.common.error_file_limit})
					return false
				}

				this.$log.console('for files')

				let post_appendix_file_div_code = ''

				for (let file of e) {

					let file_index = this.files.length + 1

					if(type.indexOf('video') > -1){
						post_appendix_file_div_code = 'CA01000002'
					}else if(type.indexOf('audio') > -1){
						post_appendix_file_div_code = 'CA01000003'
					}else{
						post_appendix_file_div_code = 'CA01000001'
					}
					this.upload_files.unshift({ type: type, src: file})
					this.files.unshift({ type: post_appendix_file_div_code, src: file})
					this.post_file_list.push({
						post_appendix_file_div_code: post_appendix_file_div_code
						, post_file_url: file
						, post_appendix_file_indexnumber: file_index
					})
				}
			}
		}
		, setVideo: function(e){

			for(let video of e.target.files){
				const reader = new FileReader()
				
				reader.onload = (e) => {
					this.video.unshift(e.target.result)
				}
				this.$log.console('video ', video)
				reader.readAsDataURL(video)
			}
		}
		, handleClick(event, ref) {
			this.$refs[ref][event]();
		}
		, handleSearch(e, platform) {
			if (platform === "youtube") this.youtubeLink = e.target.value;
			else this.vimeoLink = e.target.value;
		}
		, setVideoSize: function(type, index){
			if(this.video_size > 5){
				return false
			}
			if(type){
				this.video_size++
			}else{
				this.video.splice(this.video_size - index, 1)
				this.video_size--
			}
		}
		, setVote: function(e){
			this.$set(this.item_bbs, 'vote', e)
		}
		, resetVote: function(){
			if(!this.item_bbs.vote){

				this.item_bbs.vote = {
					title: ''
					, items: [
						{ img_src: '', title: ''}
						, { img_src: '', title: ''}
					]
					, date: this.$date.getToday('-')
					, time: []
					, is_multiple: true
				}
			}

			this.vote_fg = true
			this.is_vote = true
		}
		, removeVote: function(){
			this.item_bbs.vote = null
			this.is_vote = false
			this.vote_fg = false
		}

		, reSize: function(e){
			this.$log.console('reSize', e)
			/*
			e.target.style.height = 'auto'
			e.target.style.height = e.target.scrollHeight + 'px'

			 */
		}
		, controlVideo: function(index){
			this.$log.console('this.$refs video_' + index, this.$refs['video_' + index])
			this.$refs['video_' + index][0].play()
		}
		, isCancel: function(){
			if(confirm('게시글 작성을 취소하시겠습니까? 작성중인 내용은 모두 삭제됩니다.')){
				this.$router.back()
			}
		}
		, doPost: function(){

			try {

				if(!this.item_bbs.content || this.item_bbs.content == ''){
					throw '내용을 입력하세요'
				}

				if(this.item_board_config.board_type_code == 'CA00700001'){

					if(!this.item_bbs.title || this.item_bbs.title == ''){
						throw '제목을 입력하세요'
					}
					this.postNotice()
				}else if(this.item_board_config.board_type_code == 'CA00700002'){
					this.postCheer()
				}else if(this.item_board_config.board_type_code == 'CA00700005') {
					this.postSubscribe()
				}else{
					this.postBbs()
				}
			}catch (e){
				this.$log.console()
				this.$bus.$emit('notify', { type: 'error', message: e})
			}
		}
		, makeEnddate: function(){
			return this.item_bbs.vote.date + ' ' + this.item_bbs.vote.time.join(':')
		}
		, toFile: function(){
			this.$bus.$emit('not_pause')
		}
		, getPlan: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_subscribe_list
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
					}
					, type: true
				})

				if(result.success){
					this.items_payment = result.data.subscrp_plan_list
					// this.items = result.data
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, setPlan: function(item){
			this.items_payment.filter( (payment) => {
				if(item.subscrp_plan_number == payment.subscrp_plan_number){
					this.$set(payment, 'is_check', true)
				}else{
					this.$set(payment, 'is_check', false)
				}
			})
		}
		, onSpecial: function(){
			this.is_on_special = true
		}
		, offSpecial: function(){
			this.is_on_special = false
		}
		, onList: function(){
			this.is_on_list = true
		}
		, offList: function (){
			this.is_on_list = false
		}
		, setCopyItem: function(item){
			console.log('setCopyItem', item)
			this.item_copy = item
			this.item_bbs.content = item.contents

			if(item.post_file_list.length > 0){
				for(let [key, val] of item.post_file_list.entries()){
					console.log(key)
					this.files.push({
						name: val.post_file_name
						, src: val.file_url
						, type: val.post_appendix_file_div_code == 'CA01000001' ? 'image' : val.post_appendix_file_div_code == 'CA0100002'? 'audio' : 'video'
					})
					val.name = val.post_file_name
					val.src = val.file_url
					val.type = val.post_appendix_file_div_code == 'CA01000001' ? 'image' : val.post_appendix_file_div_code == 'CA0100002'? 'audio' : 'video'
					this.upload_files.push(val)
				}
			}
			document.body.scrollTop = 0

			this.offList()
		}
		, toPlan: function(){
			this.$bus.$emit('to', { name: 'SubscribePlane', params: {idx: this.$route.params.idx}})
		}
	}
	,async created() {
		this.$bus.$emit('onLoad', this.program)
		await this.getCartel()
		await this.getBoardList()
		await this.getBoardConfig()
		await this.getPlan()
		if(this.bbs_id){
			await this.getBbs()
		}
	}


}
</script>

<style>
	.select-board-type .on {
		color: #333 !important;
		text-shadow: 0.3px 0 #333;
	}

	.photo_upload_item {
		width: 100px;
		height: 100px;
	}

	input::placeholder{
		font-size: 14px;
	}
	textarea::placeholder{
		font-size: 14px; color: #bbb;
	}
</style>